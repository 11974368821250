const axios = require('axios');

const getValue = (key) => {
    return localStorage.getItem(key);
};
const getip = (key) => {
    return localStorage.getItem(key);
};
const getCountry = (key) => {
    return localStorage.getItem(key);
};

const instance = axios.create({
   baseURL: process.env.VUE_APP_LIVE_URL,  //Live Url Did Panel
});

instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    const nonAuthURL = ['/doLogin'];
    // if (!nonAuthURL.includes(config.url)) {
    // config.params = {
    //     authToken: getValue('authToken')
    // }
    config.headers['Authorization'] = `Basic ${getValue('auth')}`;
    config.headers['X-Forwarded-For'] = `${getip('ipAddress')},${getCountry('countryCode')}`;
    // }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;

}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log('===error.response.status===', error.response.status)
    if (error.response.status === 401 || error.response.status === 400) {
        localStorage.clear();
        window.location.href = "https://s2.didnumberprovider.com/#/login";
    }
    const errObj = {
        status: error.response.status,
        statusText: error.response.statusText,
        data: error.response.data
    }
    return Promise.reject(errObj);
});

module.exports = instance;
