const http = require('./http');

const getUserDetail = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getUserDetail?accountId=${payload.accountId}&userId=${payload.userId}&authToken=${localStorage.getItem("authToken")}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        })
    })
};

module.exports = {
    getUserDetail,
};