export default [
    {
        path: '*',
        redirect: '/didnPbx'
    },
    {
        path: '/',
        name: 'Login',
        component: () =>
            import(
                `@/pages/Authentication/login.vue`
            ),
    },
    {
        path: '/didnPbx/',
        name: 'Didn Pbx',
        component: () =>
            import(
                `@/pages/CallFlow/didnPbx.vue`
            ),
    },
];
