const http = require('./http');
const authToken = localStorage.getItem("authToken");

// Below APIs relate to Toolbar
const getPhoneNumList = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getPhoneNumList?accountId=${payload.accountId}&authToken=${localStorage.getItem("authToken")}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        })
    })
};

const getBalanceKazoo = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getBalanceKazoo?accountId=${payload.accountId}&authToken=${localStorage.getItem("authToken")}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        })
    })
};


const getrefresh = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/syncBalanceWithKazooWallet?partyId=${payload.partyId}`)
            .then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            })
    })
};



module.exports = {
    getrefresh,
    getBalanceKazoo,
    getPhoneNumList,
};