import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueClipboard from 'vue-clipboard2'
import IdleVue from "idle-vue";

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard);

document.addEventListener('contextmenu', event => event.preventDefault());
const eventsHub = new Vue();

Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    router,
    idleTime: 600000, // 10 minutes
    startAtIdle: false
});

Vue.component('loading-overlay', Loading)
Vue.config.productionTip = false
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount("#app");